import './ideaform.css';
import './commonstyle.css';
import emailjs from 'emailjs-com';

function IdeaForm()
{
    const handleSubmit = e =>
    {
      e.preventDefault();
      const formData = new FormData(e.target);
      if(formData.get("dont_spam_us_ty") !== "")
      {
        return;
      }
      if(formData.get("policyCheckbox") !== "yes")
      {
        alert("You must accept our Privacy Policy note in order to send us a message!");
        return;
      }

      emailjs.sendForm('service_p0ioyuf', 'template_kscph45', e.target, 'lwMFfPbLdB2qbtK3T')
      .then((result) => {
        alert("Your message was sent successfully!");
      }, (error) => {
          alert("Couldn't send e-mail, try again later or send an e-mail to us manually: contact@limitbreakgames.online\n\nThank you!");
      });

    }

    return (
        <>
        <h1 className='main-h1'>Send us your idea or a message!</h1>
          <form method='POST' onSubmit={handleSubmit}>
            <input name='from_name' className='idea-form-input-field' type="text" placeholder="Your name" />
            <input name='email' className='idea-form-input-field' type="email" placeholder="Your e-mail address" autoComplete='true' />
            <input name='title' className='idea-form-input-field' type='text' placeholder='Title of your message' />
            <textarea name='message' className='idea-form-input-field' id='message-field' type='text' placeholder='Your message' />
            <input type="hidden" name="dont_spam_us_ty" value="" />
            <label id='privacy-label'>
              <input type='checkbox' id='privacy-checkbox' name='policyCheckbox' value="yes" />
              By sending in your message, you accept our <a href='https://limitbreakgames.online/privacy.html' target="_blank" rel="noopener noreferrer">Privacy Policy</a>!</label>
            <input className='send-button' type='submit' value='Send' />
          </form>
        </>
      );
}

export default IdeaForm;