import './portfolio.css';
import logistixPhoto from '../images/logistix_portfolio.png';
import riegKadoret from '../images/main_menu_gif.gif';
import typeSet from '../images/typeset_portfolio.png';
import slingPuckGame from '../images/sling_puck_game.png';

function Portfolio() {
  return (
    <>
      <h1 className='main-h1'>Ongoing projects to our clients are not listed here, unless it's asked!</h1>
      <div id="main-container">
        <div className='portfolio-element'>
          <table>
          <tr>
              <td className='image-column'><img className='portfolio-image' src={slingPuckGame} alt='rieg kadoret' /></td>
              <td><div className='element-data-container'>
                <p className='element-data'>Sling Puck Game</p>
                <p className='element-data'>Platform: Web</p>
                <p className='element-data'>Description: A short and fun game that challenges your reflexes! Get all your pucks in the opponent's field.</p>
                <p className='element-data'>Try it out once their website is ready!</p>
              </div></td>
            </tr>
            <tr>
              <td className='image-column'><img className='portfolio-image' src={typeSet} alt='rieg kadoret' /></td>
              <td><div className='element-data-container'>
                <p className='element-data'>TYPESET</p>
                <p className='element-data'>Platform: Web, Android</p>
                <p className='element-data'>Description: A short and fun word game that challenges your skills and your luck! Play alone, or physically with your friends!</p>
                <p className='element-data'><a href="https://mxjasperbeatrix.itch.io/typeset" target="_blank" rel="noopener noreferrer">Try it out!</a></p>
                <p className='element-data'><a href="https://dvc.games/products/typeset-pre-order" target="_blank" rel="noopener noreferrer">Shop</a></p>
              </div></td>
            </tr>
          </table>
        </div>
        <div className='portfolio-element'>
          <table>
            <tr>
              <td className='image-column'><img className='portfolio-image' src={logistixPhoto} alt='logistix' /></td>
              <td><div className='element-data-container'>
                <p className='element-data'>Logistix</p>
                <p className='element-data'>Platform: Android</p>
                <p className='element-data'>Description: A classical arcade game where you need to get the highest score by placing the items to the ship in the best way.</p>
                <p className='element-data'><a href="https://play.google.com/store/apps/details?id=com.donix.logistix" target="_blank" rel="noopener noreferrer">Try it out!</a></p>
              </div></td>
            </tr>
          </table>
        </div>
        <div className='portfolio-element'>
          <table>
            <tr>
              <td className='image-column'>
                <img className='portfolio-image' src={riegKadoret} alt='rieg kadoret' /></td>
              <td><div className='element-data-container'>
                <p className='element-data'>Arcane Grounds: Rieg (WIP)</p>
                <p className='element-data'>Platform: PC</p>
                <p className='element-data'>Description: Help Rieg find his father through a dangerous and actionful journey. Learn to control rage, defeat the enemies and solve the puzzles.</p>
                <p className='element-data'><a href="https://www.youtube.com/@limitbreakgames.online" target="_blank" rel="noopener noreferrer">Follow!</a></p>
              </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
}

export default Portfolio;