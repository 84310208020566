import './commonstyle.css';
import './homepage.css';

import professionalismImage from '../images/diploma.svg';
import controllerImage from '../images/controller.svg';
import stopwatchImage from '../images/stopwatch.svg';
import slingpuckTestimonial from '../images/testimonials/sling_puck_testimonial.png'
import dobabTestimonial from '../images/testimonials/dobab_testimonial.png'
import typesetTestimonial from '../images/testimonials/typeset_testimonial.png'

import IdeaForm from './IdeaForm';

import { ChangePage } from '../App.js';

function Homepage()
{
    return (
        <>
          <h1 className='main-h1'>Have a <u className='keyword'>game idea</u>?<br/>You are in the <u className='keyword'>best place</u> to bring it to life.</h1>
          <h2 className='main-h2'>Your benefits</h2>
          <div id='benefit-container'>
            <div className='benefit'>
              <img src={stopwatchImage} className='benefit-images' />
              <p className='benefit-text'>You save a lot of time!</p>
            </div>
            <div className='benefit'>
              <img src={professionalismImage} className='benefit-images' />
              <p className='benefit-text'>Use the knowledge of industry professionals!</p>
            </div>
            <div className='benefit'>
              <img src={controllerImage} className='benefit-images' />
              <p className='benefit-text'>Get your game made by actual gamers!</p>
            </div>
          </div>

          <h2 className='main-h2'>What do our clients say about us?</h2>
          <div id='benefit-container'>
            <div className='benefit'>
              <img src={slingpuckTestimonial} className='testimonial-images' />
            </div>
            <div className='benefit'>
              <img src={dobabTestimonial} className='testimonial-images' />
            </div>
            <div className='benefit'>
              <img src={typesetTestimonial} className='testimonial-images' />
            </div>
          </div>
        </>
      );
}

export default Homepage;