import './hamburger.css';

function HamburgerMenu({openAboutUs, openPortfolio, openIdeaForm}) {
    return (
      <>
        <ul id="menu-button-list" >
          <li><button class="hamburger-menu-button" onClick={openPortfolio}>Portfolio</button></li>
          <li><button class="hamburger-menu-button"onClick={openAboutUs}>Why Work With Us?</button></li>
          <li><button class="hamburger-menu-button" id="book-appointment-button" onClick={openIdeaForm}>Tell us your idea</button></li>
        </ul>
      </>
    );
}

export default HamburgerMenu;