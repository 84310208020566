import logo from './images/lb_games_logo.png';
import hamburgericon from './images/hamburger_icon.png';
import './App.css';
import './components/commonstyle.css';
import HamburgerMenu from './components/HamburgerMenu';
import Homepage from './components/Homepage';
import IdeaForm from './components/IdeaForm';
import Portfolio from './components/Portfolio';
import { useState } from 'react';

function App() {

  const [menuOpen, OpenMenu] = useState(false);
  const [openedPage, OpenPage] = useState(Homepage);

  function ShowPage(page)
  {
    console.log("change page run");
    OpenMenu(false);
    OpenPage(page);
  }

  return (
    <>
      <div id="navbar">
        <img src={logo} id="navbar-logo" />
        <div id="navbar-button-container">
          <button class="navbar-button" onClick={() => OpenPage(Portfolio)}>Portfolio</button>
          <button class="navbar-button" onClick={() => OpenPage(Homepage)}>Why Work With Us?</button>
          <button class="navbar-button" id="book-appointment-button" onClick={() => OpenPage(IdeaForm)}>Contact us!</button>
        </div>
        <button class="navbar-button" id="hamburger-button" onClick={() => OpenPage(!menuOpen)}>
          <img src={hamburgericon} id="hamburger-icon" />
        </button>
      </div>
      {menuOpen ? <HamburgerMenu openAboutUs={() => ShowPage(Homepage)} openPortfolio={() => ShowPage(Portfolio)} openIdeaForm={() => ShowPage(IdeaForm)} /> : null} 
      {openedPage}
      <div id="footer">
        <div id="copyright">© Limit Break Games</div>
        <div id="socials"><a href='https://www.instagram.com/limitbreak.gamedev/'>INSTAGRAM</a></div>
      </div>
    </>
  );
}

export default App;
